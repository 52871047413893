"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageDomElementService = void 0;
class ImageDomElementService {
    constructor() {
    }
    static get Instance() {
        return this._instance || (this._instance = new this());
    }
    loadAsync(url) {
        return new Promise((resolve, reject) => {
            var image = new Image();
            image.src = url;
            image.onload = () => resolve(image);
            image.onerror = () => reject(new Error('could not load image'));
        });
    }
}
exports.ImageDomElementService = ImageDomElementService;
