"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScrollHelper = void 0;
class ScrollHelper {
    constructor() {
        this.initScrollEventListener();
    }
    static get Instance() {
        // Do you need arguments? Make it a regular static method instead.
        return this._instance || (this._instance = new this());
    }
    initScrollEventListener() {
    }
    disableScrolling() {
        window.onscroll = function () { window.scrollTo(0, 0); };
    }
    enableScrolling() {
        window.onscroll = function () { };
    }
    scrollToElement(elementId, smooth) {
        const element = document.getElementById(elementId);
        console.log(elementId);
        if (smooth) {
            element.scrollIntoView({ behavior: "smooth" });
        }
        else {
            element.scrollIntoView(true);
        }
    }
    get scrollY() {
        return window.scrollY;
    }
    get scrollX() {
        return window.scrollX;
    }
}
exports.ScrollHelper = ScrollHelper;
