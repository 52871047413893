"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceTypeService = void 0;
class DeviceTypeService {
    constructor() {
    }
    static get Instance() {
        return this._instance || (this._instance = new this());
    }
    isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    getPlatform() {
        if (navigator) {
            return navigator.platform;
        }
        else {
            return "error: navigator is undefined";
        }
    }
    isIOSDevice() {
        //@ts-ignore
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
            //@ts-ignore
        ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }
    isSafariBrowser() {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }
}
exports.DeviceTypeService = DeviceTypeService;
